
//const rutaBackend="https://backendcompanywalter-dev-qbzs.3.us-1.fl0.io"
//const rutaBackend="http://54.232.44.66:8080"
const rutaBackend="https://backend-company-walter.vercel.app"
//const rutaBackend="http://localhost:3002"
//const rutaBackend="https://backendcompanywalter.up.railway.app"




export default rutaBackend